"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { Device, Call } from "@twilio/voice-sdk";

interface CallContextType {
  device: Device | null;
  incomingCall: Call | null;
  outgoingCall: Call | null;
  inCallState: boolean;
  callEnded: string | undefined | null;
  callEndedDirection: "Incoming" | "Outgoing" | null;
  callStartTime: number | null;
  elapsedTime: number;
  showOnGoingCallDiv: boolean;
  phoned: string | undefined | null;
  phonedNumber: string | null;
  phonedIsContact: boolean;
  ringing: boolean;
  showMakeCallDiv: boolean;
  phoneNumberInputted: string | null;
  makeCallRequested: boolean;
  hasHandoffPhoneNumber: boolean;
  setDevice: (device: Device | null) => void;
  setIncomingCall: (call: Call | null) => void;
  setOutgoingCall: (call: Call | null) => void;
  setInCallState: (inCallState: boolean) => void;
  setCallEnded: (callEnded: string | undefined | null) => void; //callSid
  setCallEndedDirection: (
    callEndedDirection: "Incoming" | "Outgoing" | null,
  ) => void; //callSid
  setCallStartTime: (callStartTime: number | null) => void;
  setElapsedTime: (elapsedTime: number) => void;
  setShowOnGoingCallDiv: (showOnGoingCallDiv: boolean) => void;
  setPhoned: (phoned: string | undefined | null) => void;
  setPhonedNumber: (phoned: string | null) => void;
  setPhonedIsContact: (phonedIsContact: boolean) => void;
  setRinging: (ringing: boolean) => void;
  setShowMakeCallDiv: (showMakeCallDiv: boolean) => void;
  setPhoneNumberInputted: (phoneNumberInputted: string | null) => void;
  handleOutgoingCallDisconnected: (call: Call) => void;
  handleOutgoingCallAccepted: (call: Call) => void;
  handleOutgoingCallCancelled: (call: Call) => void;
  makeCall: () => void;
  setMakeCallRequested: (makeCallRequested: boolean) => void;
}

const CallContext = createContext<CallContextType | undefined>(undefined);

export const useCallContext = () => {
  const context = useContext(CallContext);
  if (!context) {
    throw new Error("useCallContext must be used within a CallProvider");
  }
  return context;
};

export const CallProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [device, setDevice] = useState<Device | null>(null);
  const [incomingCall, setIncomingCall] = useState<Call | null>(null);
  const [outgoingCall, setOutgoingCall] = useState<Call | null>(null);
  const [inCallState, setInCallState] = useState<boolean>(false);
  const [callEnded, setCallEnded] = useState<string | undefined | null>(null);
  const [callEndedDirection, setCallEndedDirection] = useState<
    "Incoming" | "Outgoing" | null
  >(null);
  const [callStartTime, setCallStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showOnGoingCallDiv, setShowOnGoingCallDiv] = useState<boolean>(false);
  const [phoned, setPhoned] = useState<string | undefined | null>(null);
  const [phonedNumber, setPhonedNumber] = useState<string | null>(null);
  const [phonedIsContact, setPhonedIsContact] = useState<boolean>(false);
  const [ringing, setRinging] = useState<boolean>(false);
  const [showMakeCallDiv, setShowMakeCallDiv] = useState<boolean>(false);
  const [phoneNumberInputted, setPhoneNumberInputted] = useState<string | null>(
    null,
  );
  const [makeCallRequested, setMakeCallRequested] = useState<boolean>(false);
  const [hasHandoffPhoneNumber, setHasHandoffPhoneNumber] = useState(false);

  const handleOutgoingCallDisconnected = async (call: Call) => {
    if (!call) {
      return;
    }
    call.disconnect();

    //nullify outgoing call
    setOutgoingCall(null);

    //no longer in a call
    setInCallState(false);

    //nullify phoned
    // setPhoned(null);

    //reset phoned is a contact
    setPhonedIsContact(false);

    //hide on going call div
    setShowOnGoingCallDiv(false);

    //nullify call start time
    setCallStartTime(null);

    setCallEnded(call.parameters.CallSid);
    setCallEndedDirection("Outgoing");

    setPhoneNumberInputted("");
    setMakeCallRequested(false);
  };

  const handleOutgoingCallCancelled = async (call: Call) => {
    if (!call) {
      return;
      //invalid call
      // nullify all vars
    }
    call.disconnect();

    //nullify outgoing call
    setOutgoingCall(null);

    //no longer in a call
    setInCallState(false);

    //nullify phoned
    // setPhoned(null);

    //reset phoned is a contact
    setPhonedIsContact(false);

    //hide on going call div
    setShowOnGoingCallDiv(false);

    //nullify call start time
    setCallStartTime(null);

    setCallEnded(call.parameters.CallSid);
    setCallEndedDirection("Outgoing");

    setPhoneNumberInputted("");
    setMakeCallRequested(false);
  };

  const handleOutgoingCallAccepted = async (call: Call) => {
    //when a call is accepted

    setOutgoingCall(call);

    //show div of ongoing call
    setShowOnGoingCallDiv(true);

    //msignify we are currently in a call
    setInCallState(true);

    //start timer
    setCallStartTime(Date.now());
  };

  const makeCall = async () => {
    if (!device || !phoneNumberInputted) {
      console.error(
        "Device was not initialized or no phone number was inputted!",
      );

      return;
    }

    const params = { To: phoneNumberInputted };
    const call = await device.connect({ params });
    //set current outgoing call

    setInCallState(true);

    setCallStartTime(Date.now());
    //set phoned to the number we are calling
    setPhoned(phoneNumberInputted);

    //set ringing to true until the call is accepted
    setRinging(true);

    //hide make call div
    setShowMakeCallDiv(false);

    //show ongoing call div
    setShowOnGoingCallDiv(true);

    //safety check
    setIncomingCall(null);

    call.on("accept", handleOutgoingCallAccepted);
    call.on("disconnect", handleOutgoingCallDisconnected);
    call.on("cancel", handleOutgoingCallCancelled);

    setOutgoingCall(call);
  };

  useEffect(() => {
    if (phoneNumberInputted && phoneNumberInputted.length > 4) {
      makeCall();
    }
  }, [makeCallRequested]);

  return (
    <CallContext.Provider
      value={{
        //states
        device,
        incomingCall,
        outgoingCall,
        inCallState,
        callEnded,
        callEndedDirection,
        callStartTime,
        elapsedTime,
        showOnGoingCallDiv,
        phoned,
        phonedNumber,
        phonedIsContact,
        ringing,
        showMakeCallDiv,
        phoneNumberInputted,
        makeCallRequested,
        hasHandoffPhoneNumber,

        //setters
        setDevice,
        setIncomingCall,
        setOutgoingCall,
        setInCallState,
        setCallEnded,
        setCallEndedDirection,
        setCallStartTime,
        setElapsedTime,
        setShowOnGoingCallDiv,
        setPhoned,
        setPhonedNumber,
        setPhonedIsContact,
        setRinging,
        setShowMakeCallDiv,
        setPhoneNumberInputted,
        setMakeCallRequested,
        // functions
        handleOutgoingCallDisconnected,
        handleOutgoingCallAccepted,
        handleOutgoingCallCancelled,
        makeCall,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};
